import { useEffect } from 'react'
import { Router } from 'next/router'
import { User } from 'api/dto'
import { useSession } from 'auth'
import analytics from '@perry/app/lib/analytics/analytics'

const init = (user: User) => {
  analytics.identify(user.id.toString(), {
    firstName: user.firstName,
    lastName: user.lastName,
  })

  // Fire initial page view
  analytics.page()
  // Fire page views on routing
  Router.events.on('routeChangeComplete', handleRouteChange)
}

const reset = () => {
  analytics.reset()
  Router.events.off('routeChangeComplete', handleRouteChange)
}

const handleRouteChange = () => {
  // We need to wrap it in a rAF to ensure the correct data is sent to Segment
  // https://github.com/zeit/next.js/issues/6025
  if (window?.requestAnimationFrame) {
    window.requestAnimationFrame(() => {
      analytics.page()
    })
  } else {
    analytics.page()
  }
}

const useAnalytics = (): void => {
  const { user } = useSession()

  useEffect(() => {
    if (user?.id) {
      init(user)
    } else if (analytics?.user()?.userId) {
      reset()
    }

    return () => reset()
  }, [user?.id])
}

export default useAnalytics
