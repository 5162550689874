import { FC } from 'react'
import { LinkIcon } from '@heroicons/react/solid'
import { InvestmentOpportunityAttribute } from 'api/cms'
import clsx from 'clsx'

export const AttributesSection: FC<{
  fields: InvestmentOpportunityAttribute[]
  className?: string
}> = ({ fields = [], className }) => {
  return (
    <div className={clsx('flex flex-col gap-1', className)}>
      {fields.map((field, index) => (
        <AttributeComponent key={index} attribute={field} />
      ))}
    </div>
  )
}

const AttributeComponent: FC<{ attribute: InvestmentOpportunityAttribute }> = ({ attribute }) => {
  const { value, label, type } = attribute
  switch (type) {
    case 'link':
      return (
        <div className="flex">
          <a
            className="text-sm text-orange-600 hover:text-orange-800 leading-5 font-semibold"
            href={attribute.value}
          >
            <LinkIcon className="w-4 h-4  mr-[10px]" />
            <span>{label || value}</span>
          </a>
        </div>
      )
    default:
      return (
        <div className="flex gap-x-4 text-base font-normal">
          <div className="text-sm text-deep-teal-800 capitalize">{attribute.label}</div>
          <div className="text-sm text-deep-teal-800 font-semibold">{attribute.value}</div>
        </div>
      )
  }
}
