import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import { useAxios } from 'api/lib'
import { useAmityFile } from '../../pages/groups/GroupsHome/useAmityQuery'

export const GroupsWidgetItem: FC<{ group: Amity.Community }> = ({ group }) => {
  const file = useAmityFile(group.avatarFileId)

  const [viewUrl, setViewUrl] = useState<string>()
  const { data: investmentOpportunityId } = useAxios<number | null>(
    `/api/investment-opportunities/groupId/${group.communityId}`,
  )

  useEffect(() => {
    investmentOpportunityId
      ? setViewUrl(`/investments/${investmentOpportunityId}`)
      : setViewUrl(`/groups/${group.communityId}`)
  }, [group?.isPublic, investmentOpportunityId])

  return (
    viewUrl && (
      <Link href={viewUrl}>
        <div className="flex items-center">
          <div className="shrink-0 block">
            {file?.fileUrl ? (
              <div className="mr-2 h-6 w-6 bg-taupe-600 rounded-full overflow-hidden">
                {file && <img className="h-full w-full" src={file.fileUrl} />}
              </div>
            ) : (
              <div className="mr-2 h-6 w-6 flex justify-center items-center text-base text-white bg-taupe-600 rounded-full overflow-hidden">
                {group.displayName.charAt(0)}
              </div>
            )}
          </div>
          <div className="leading-none text-xs break-words text-deep-teal-800">
            {group.displayName}
          </div>
        </div>
      </Link>
    )
  )
}
