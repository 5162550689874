import { FC } from 'react'
import clsx from 'clsx'

export interface TagProps {
  readonly label: string
  readonly disabled?: boolean
  readonly onRemove?: () => void
}

const Tag: FC<TagProps> = ({ label, disabled, onRemove }) => {
  return (
    <span
      className={clsx(
        'inline-flex items-center py-0.5 pl-2 rounded-full text-sm font-medium bg-deep-teal-50 text-deep-teal-500',
        disabled ? 'pr-2' : 'pr-0.5',
      )}
    >
      {label}

      {onRemove && !disabled && (
        <button
          type="button"
          className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-deep-teal-500 hover:bg-deep-teal-100 focus:outline-none"
          onClick={() => onRemove()}
        >
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      )}
    </span>
  )
}

export default Tag
