import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'
import Error from '../Field/Error'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label?: string | ReactNode
  readonly error?: Pick<FieldError, 'message'>
  readonly hideError?: boolean
}

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<any, CheckboxProps>(
  ({ name, label, hideError, className, error, disabled, ...props }, ref) => {
    return (
      <div className="flex flex-col">
        <div className={clsx({ 'opacity-25': disabled }, className, 'flex')}>
          <input
            type="checkbox"
            className="focus:ring-orange-500 h-5 w-5 text-orange-500 border-gray-300 rounded cursor-pointer"
            disabled={disabled}
            name={name}
            id={name}
            ref={ref}
            {...props}
          />

          {label && (
            <label htmlFor={name} className="ml-2 cursor-pointer">
              {label}
            </label>
          )}
        </div>
        {!hideError && (
          <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
            {error?.message}
          </Error>
        )}
      </div>
    )
  },
)
