import { ComponentProps, FC, forwardRef } from 'react'
import clsx from 'clsx'
import { useMarkdown } from '@perry/app/lib'

interface MarkdownSectionProps extends ComponentProps<'div'> {
  markdown?: string
  contentString?: string
}

// eslint-disable-next-line react/display-name
export const MarkdownSectionComponent: FC<MarkdownSectionProps> = forwardRef(
  ({ className, markdown, contentString }, ref) => {
    const parsedMarkdownString = useMarkdown(markdown || '', { sanitize: false })

    return (
      <div
        className={clsx('prose prose-md', className)}
        role="region"
        ref={ref}
        dangerouslySetInnerHTML={{ __html: contentString || parsedMarkdownString }}
      />
    )
  },
)

export default MarkdownSectionComponent
