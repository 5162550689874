import { FC } from 'react'
import { InvestmentOpportunityCard } from 'api/cms'
import { AttributesSection } from './AttributesSection'

export const BackSide: FC<{ investmentOpportunityCard: Partial<InvestmentOpportunityCard> }> = ({
  investmentOpportunityCard,
}) => {
  return (
    <>
      <div className="font-medium text-base text-rangitoto-500 mb-4">
        {investmentOpportunityCard.title}
      </div>

      {investmentOpportunityCard.backPanel.map((fields, index) => (
        <div key={index}>
          <AttributesSection fields={fields} />
          {index !== investmentOpportunityCard.backPanel.length - 1 && (
            <div className="w-full border-t border-color-rangitoto-200 my-4" />
          )}
        </div>
      ))}
    </>
  )
}
