import { useFlags } from 'launchdarkly-react-client-sdk'

export enum LDFlag {
  SocialConnections = 'socialConnections',
  SocialProfileNominations = 'socialProfileNominations',
  GroupMembersPageSize = 'groupMembersPageSize',
  MemberSocialProfilePortfolioV2 = 'memberProfilePortfolioV2',
}

export const useFlag = <T>(flag: LDFlag): T => {
  const flags = useFlags() || {}
  return flags[flag]
}
