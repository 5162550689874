import { useContext, useEffect } from 'react'
import {
  AgreementWithSignatureDate,
  GetSessionResponse,
  SelfAccreditationEnum,
  Subscription,
  User,
} from 'api/dto'
import { axios } from 'api/lib'
import create from 'zustand'
import { AuthContext, AuthContextValue, UserCustomClaims } from './AuthContext'

export interface Session extends SessionStore {
  readonly auth: AuthContextValue
}

export const useSession = (): Session => {
  const auth = useContext(AuthContext)
  const store = useSessionStore()

  useEffect(() => {
    const { isLoading: isStoreLoading, isInitialized: isStoreInitialized } =
      useSessionStore.getState()

    // Do nothing while data is loaded
    if (isStoreLoading || auth?.isLoading) {
      return
    }

    if (!auth?.isAuthenticated && isStoreInitialized) {
      store.clean()
      return
    }

    if (auth?.isAuthenticated && !isStoreInitialized) {
      if (!!hasUserId(auth.user)) {
        store.reload()
      } else if (!auth.error) {
        store.setIsInitialized(true)
      }
    }
  }, [auth?.isAuthenticated, auth?.isLoading, auth?.error, auth?.user?.userId])

  return { auth, ...store }
}

export interface SessionStore {
  readonly user?: User
  readonly sa?: SelfAccreditationEnum[]
  readonly subscription?: Subscription
  readonly agreements?: AgreementWithSignatureDate[]
  readonly socialProfileNotActivated?: boolean

  /**
   * Indicates when session request is in progress. Prevents multiple simultaneous session
   * requests, especially when the app is just started and multiple components may try send the request.
   */
  readonly isLoading: boolean

  /**
   * Initially `false`. Set to `true` only once, after the very first session fetch.
   */
  readonly isInitialized: boolean

  /**
   * Forces to refetch all session data for the currently logged in user.
   */
  reload(): Promise<void>

  /**
   * Clean user data and set store to the default state.
   */
  clean(): void

  setIsInitialized(value: boolean): void
}

const useSessionStore = create<SessionStore>((set, get) => ({
  isInitialized: false,
  isLoading: false,

  clean(): void {
    set({
      isInitialized: false,
      isLoading: false,
      user: undefined,
      sa: undefined,
      subscription: undefined,
      agreements: undefined,
      socialProfileNotActivated: undefined,
    })
  },

  async reload(): Promise<void> {
    if (!get().isLoading) {
      set({ isLoading: true })
      return axios
        .get<GetSessionResponse>(`/api/session`)
        .then(({ data }) => {
          set({
            subscription: data.subscription,
            agreements: data.agreements,
            user: data.user,
            socialProfileNotActivated: data.socialProfileNotActivated,
            sa: data.sa,
            isInitialized: true,
            isLoading: false,
          })
        })
        .catch(() =>
          set({
            isInitialized: true,
            isLoading: false,
          }),
        )
    }
  },

  setIsInitialized(isInitialized: boolean): void {
    set({ isInitialized })
  },
}))

/**
 * Custom claim userId indicates that the user has already created an account in our DB,
 * i.e. passed at least the Create Your Account step.
 */
const hasUserId = (user: UserCustomClaims | undefined): boolean => {
  return !!user?.userId
}
