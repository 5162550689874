import { ComponentProps, FC, Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/outline'
import { useSession } from 'auth'
import clsx from 'clsx'
import { NextLink } from '@perry/app/components/navigation'
import { getInitials, useUserNavigation } from './userNavigation'

interface UserMenuProps extends ComponentProps<'div'> {
  standalone?: boolean
}

const UserMenu: FC<UserMenuProps> = ({ className, standalone, ...props }) => {
  const { isInitialized, user } = useSession()
  const [initials, setInitials] = useState('')
  useEffect(() => {
    if (isInitialized && user) {
      setInitials(getInitials(user))
    }
  }, [isInitialized, user?.firstName, user?.lastName])

  const menu = useUserNavigation()

  return (
    <Menu as="div" className={clsx('relative', className)} {...props}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="max-w-xs bg-taupe-200 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              data-cy="user-menu-button"
            >
              <span className="sr-only">Open user menu</span>
              <span className="h-8 w-8 rounded-full flex items-center justify-center">
                {initials ? (
                  <span className="text-sm font-brand lining-nums font-medium leading-none text-deep-teal-500">
                    {initials}
                  </span>
                ) : (
                  <UserIcon data-testid="user-icon" className="h-5 w-5 text-deep-teal-500" />
                )}
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                'right-0 z-10 absolute w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                standalone ? 'rounded' : 'origin-top-right rounded-b mt-4',
              )}
              static
            >
              {menu.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <NextLink
                      className={clsx(
                        'block p-2 pr-4 pl-3 whitespace-nowrap border-l-4 w-full',
                        'text-sm leading-5',
                        !active && !item.current && 'border-transparent text-deep-teal-800',
                        active && !item.current && 'bg-taupe-300',
                        active && 'border-transparent  text-text-deep-teal-800',
                        item.current && 'bg-orange-50 border-orange-500 text-orange-800',
                      )}
                      href={item.href}
                      onClick={item.onClick}
                      target={item.target}
                    >
                      {item.name}
                    </NextLink>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default UserMenu
