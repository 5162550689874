import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export type BadgeType = 'error' | 'warning' | 'success' | 'info' | 'default'

export type BadgeSize = 'default' | 'large'

export interface BadgeProps extends ComponentProps<'span'> {
  readonly type?: BadgeType
  readonly size?: BadgeSize
}

type BadgeThemeColors = {
  readonly bg: string
  readonly text: string
}

const BADGE_TYPES: Readonly<Record<BadgeType, BadgeThemeColors>> = {
  default: {
    bg: 'bg-rangitoto-100',
    text: 'text-rangitoto-800',
  },
  info: {
    bg: 'bg-blue-50',
    text: 'text-blue-700',
  },
  error: {
    bg: 'bg-elsalva-100',
    text: 'text-elsalva-700',
  },
  success: {
    bg: 'bg-success-green-300',
    text: 'text-success-green-800',
  },
  warning: {
    bg: 'bg-amber-50',
    text: 'text-amber-700',
  },
}

const BADGE_SIZES: Readonly<Record<BadgeSize, string>> = {
  default: 'text-xs leading-4',
  large: 'text-sm leading-5',
}

export const Badge: FC<BadgeProps> = ({
  className,
  children,
  type = 'dismiss',
  size = 'default',
  ...props
}) => {
  const typeCss = BADGE_TYPES[type]
  const sizeCss = BADGE_SIZES[size]

  return (
    <span
      className={clsx(
        'px-3 py-0.5 inline-flex font-semibold rounded-full',
        typeCss.bg,
        typeCss.text,
        sizeCss,
        className,
      )}
      {...props}
    >
      {children}
    </span>
  )
}
