import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

// There are a lot of other features which can be smoothly incorporated:
// https://tailwindui.com/components/application-ui/elements/buttons

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Type = 'primary' | 'secondary' | 'regular' | 'ghost' | 'disabled' | 'disabled-primary'

type AnchorAndButton = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>

export interface ButtonProps extends AnchorAndButton {
  ref?: any
  size?: Size
  uiType?: Type
  disabled?: boolean
}

const SIZE_CLASSES_MAP: { [key in Size]: string } = {
  xs: 'px-2.5 py-1.5 text-xs',
  sm: 'px-3 py-1.5 text-sm',
  md: 'px-4 py-2 text-sm',
  lg: 'px-4 py-2 text-base',
  xl: 'px-6 py-3 text-base',
}

const TYPE_CLASSES_MAP: { [key in Type]: string } = {
  primary:
    'h-fit text-orange-50 border-transparent  bg-orange-600 hover:bg-orange-700 focus:bg-orange-800',
  secondary:
    'h-fit text-orange-600 border-transparent bg-orange-50 hover:bg-orange-100 focus:bg-orange-200',
  regular:
    'h-fit shadow-sm text-deep-teal-800 border-taupe-600 bg-white hover:bg-deep-teal-50 hover:border-deep-teal-200 focus:bg-deep-teal-100 focus:border-deep-teal-300',

  ghost: 'h-fit border-transparent bg-transparent text-orange-400 hover:text-orange-200',

  disabled:
    'h-fit shadow-sm text-deep-gray-200 border-transparent bg-deep-gray-50 pointer-events-none',
  'disabled-primary':
    'shadow-sm text-deep-gray-200 border-transparent bg-deep-gray-50 pointer-events-none',
}

const BASE_BUTTON_CLASSES = clsx(
  'items-center inline-block',
  'text-center',
  'border rounded-md ',
  'font-medium',
  'focus:outline-none focus:ring-0',
)

export const Button: FC<ButtonProps> = ({
  ref,
  size = 'md',
  uiType = 'primary',
  disabled,
  className,
  children,
  href,
  ...props
}) => {
  if (disabled) {
    uiType = uiType === 'primary' ? 'disabled-primary' : 'disabled'
  }

  const HtmlElement = href ? 'a' : 'button'

  const actualComponent = (
    <HtmlElement
      disabled={disabled}
      className={clsx(
        BASE_BUTTON_CLASSES,
        SIZE_CLASSES_MAP[size],
        TYPE_CLASSES_MAP[uiType],
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
    </HtmlElement>
  )

  return (
    <>
      {href ? (
        <Link href={href} legacyBehavior>
          {actualComponent}
        </Link>
      ) : (
        actualComponent
      )}
    </>
  )
}
