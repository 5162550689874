import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

export interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  readonly name: string
}

const Label: FC<LabelProps> = ({ name, children, className, ...props }) => {
  return (
    <label
      className={clsx('block text-sm leading-5 font-medium text-deep-teal-900', className)}
      htmlFor={name}
      {...props}
    >
      {children}
    </label>
  )
}

export default Label
