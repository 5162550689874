import { useEffect, useState } from 'react'
import { FC, PropsWithChildren, useCallback } from 'react'
import { useAuth0, User as Auth0User } from '@auth0/auth0-react'
import { RoleEnum } from 'api/dto'
import { AuthContext, USER_CUSTOM_CLAIMS, UserCustomClaims, LoginOptions, LogoutOptions, GetAccessTokenOptions } from 'auth'

const getUserCustomClaims = (auth0User: Auth0User | undefined) =>
  auth0User?.[USER_CUSTOM_CLAIMS] as UserCustomClaims

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth0 = useAuth0()
  const [isAuthenticated, setIsAuthenticated] = useState(auth0.isAuthenticated)
  const [isLoading, setIsLoading] = useState(auth0.isLoading)
  const [error, setError] = useState<any>(auth0.error)
  const userCustomClaims = getUserCustomClaims(auth0.user)
  const [user, setUser] = useState<UserCustomClaims>(userCustomClaims)
  const [role, setRole] = useState<RoleEnum>(userCustomClaims?.roles?.[0])

  const auth0UserId = getUserCustomClaims(auth0.user)?.userId

  const login = useCallback(
    async (options?: LoginOptions) => {
      await auth0.loginWithRedirect(options)
    },
    [auth0],
  )

  const logout = useCallback(
    async (options?: LogoutOptions) => {
      auth0.logout(options)
    },
    [auth0],
  )

  const getAccessToken = useCallback(
    async (options?: GetAccessTokenOptions) => await auth0.getAccessTokenSilently(options),
    [auth0UserId],
  )

  useEffect(() => {
    // do nothing during loading
    setIsLoading(auth0.isLoading)

    if (!auth0.isLoading) {
      // parse custom user claims if authenticated
      if (auth0.isAuthenticated) {
        const userCustomClaims = getUserCustomClaims(auth0.user)
        setRole(userCustomClaims?.roles?.[0])
        setUser(userCustomClaims)
        setIsAuthenticated(true)
        setError(undefined)
      } else {
        // clean session if not
        setRole(undefined)
        setUser(undefined)
        setIsAuthenticated(false)
        setError(auth0.error)
      }
    }
  }, [auth0.isLoading, auth0.isAuthenticated, auth0.error, JSON.stringify(auth0.user)])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        isLoading,
        error,
        getAccessToken,
        user,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
