import { ComponentProps, FC } from 'react'
import { UserSocialProfileMemberStatus, UserSocialProfileMemberStatusEnum } from 'api/dto'
import clsx from 'clsx'
import { Badge, BadgeSize } from '../Badge'
interface MemberStatusProps extends ComponentProps<'div'> {
  status?: UserSocialProfileMemberStatusEnum
  size?: BadgeSize
}

export const MemberStatus: FC<MemberStatusProps> = ({ className, status, size }) => {
  const isActive = status === UserSocialProfileMemberStatus.ACTIVE

  return (
    <Badge
      className={clsx(className, 'capitalize items-center')}
      type={isActive ? 'success' : 'default'}
      size={size}
    >
      {status?.toLowerCase()}
    </Badge>
  )
}
