import { FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { Badge, BadgeProps } from './Badge'

export interface LinkBadgeProps extends BadgeProps {
  readonly href: string
}

const LinkBadge: FC<LinkBadgeProps> = ({ className, children, href, ...props }) => {
  return (
    <Badge className={clsx('flex', className)} {...props}>
      <span className="self-center text-xl leading-4 mb-0.5 mr-2">&bull;</span>
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    </Badge>
  )
}

export default LinkBadge
