// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

import { MutableRefObject, useEffect } from 'react'

export const useOutsideClick = (ref: MutableRefObject<HTMLElement>, callback: () => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
