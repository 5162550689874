import {
  ComponentProps,
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { Combobox } from '@headlessui/react'
import { SearchIcon, XCircleIcon } from '@heroicons/react/solid'
import { useAmity } from 'amity/lib'
import { searchUsers } from 'amity/lib'
import clsx from 'clsx'
import { useOutsideClick } from '@perry/app/lib/useOutsideClick'
import { AMITY_PATH } from '@perry/server/amity/const'
import { useScrollLock } from './useScrollLock'
import Avatar from '../../content/Avatar/Avatar'
import { MemberStatus } from '../../content/MemberStatus/MemberStatus'

const MIN_CHAR_COUNT = 3
const MAX_RESULTS = 5

interface SocialSearchProps {
  className?: string
  isFocused?: boolean
  setIsFocused?: (flag: boolean) => void
}

export const SocialSearch: FC<SocialSearchProps> = ({ className, isFocused, setIsFocused }) => {
  const router = useRouter()
  const { isConnected } = useAmity()
  const [members, setMembers] = useState([])
  const [showNotEnoughLengthHint, setShowNotEnoughLengthHint] = useState(false)
  const [selected, setSelected] = useState<Amity.User | undefined>()
  const { register, setFocus, setValue, watch } = useForm({
    defaultValues: {
      search: router.query?.query ?? '',
    },
  })
  const ref = useRef<HTMLDivElement>(null)

  const search = watch('search') as string
  const setSearch = (search: string) => setValue('search', search)
  const setSearchFocus = () => setFocus('search')

  const isSearchEmpty = search?.length === 0
  const searchHasEnoughLength = search?.length >= MIN_CHAR_COUNT
  const searchHasLength = search?.length > 0
  const thereAreSearchResults = members.length > 0
  const thereIsNoResult = members.length === 0

  const handleGoToAllResults = () => {
    router.push('/search?query=' + search)
  }

  const tryToGoToAllResults = () => {
    if (searchHasEnoughLength) {
      handleGoToAllResults()
    } else if (searchHasLength) {
      setShowNotEnoughLengthHint(true)
    } else {
      setShowNotEnoughLengthHint(false)
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      tryToGoToAllResults()
      event.stopPropagation()
      event.preventDefault()
    } else if (event.key === 'Escape') {
      handleOutsideClick() // will set focused flag to false and move component to initial state
      event.currentTarget.blur() // remove focus from input
      event.stopPropagation()
      event.preventDefault() // default behavior is clear input and keep it focused, need prevent it
    } else if (event.key === 'Tab') {
      handleOutsideClick() // will set focused flag to false and move component to initial state
      event.stopPropagation()
    } else {
      setShowNotEnoughLengthHint(false)
    }
  }

  const handleMagnifierClick: MouseEventHandler<HTMLDivElement> = () => {
    if (isFocused) {
      tryToGoToAllResults()
    } else {
      // restore focus because we loose it after click outside input
      setSearchFocus()
    }
  }

  const handleClearSearch: MouseEventHandler<HTMLDivElement> = (event) => {
    setSearch('')
    setMembers([])
    if (isFocused) {
      // restore focus because we loose it after click outside input
      setSearchFocus()
    }
    event.stopPropagation()
    event.preventDefault()
  }

  const handleFocus = () => {
    setIsFocused && setIsFocused(true)
  }

  const handleOutsideClick = () => {
    setIsFocused && setIsFocused(false)
  }

  const handleOverlayClick: MouseEventHandler = (event) => {
    handleOutsideClick() // will set focused flag to false and move component to initial state
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    if (selected) {
      router.push(`/members/${selected.userId}`)
    }
  }, [selected])

  useOutsideClick(ref, handleOutsideClick)

  useEffect(() => {
    if (searchHasEnoughLength) {
      if (isConnected) {
        searchUsers({ displayName: search, page: { limit: MAX_RESULTS } })
          .then(({ users: members }) => setMembers(members || []))
          .catch(() => setMembers([]))
      }
    } else if (members.length) {
      setMembers([])
    }
  }, [isConnected, search, searchHasEnoughLength])

  useScrollLock(isFocused)

  const goToAllMembersResultsButton = (
    <li className="mx-6 mb-4 space-x-2">
      <span className="text-deep-teal-300 text-sm leading-5 font-medium tracking-wide uppercase">
        Members
      </span>
      <a
        onClick={handleGoToAllResults}
        className="text-orange-600 text-sm leading-5 font-semibold cursor-pointer hover:text-orange-800"
      >
        View all results
      </a>
    </li>
  )

  const noResultsLabel = (
    <div className={'mx-6 text-sm leading-5 font-medium text-deep-teal-500'}>
      No members have been found
    </div>
  )

  const notEnoughSearchLength = (
    <div className={'mx-6 text-sm leading-5 font-medium text-deep-teal-500'}>
      Please enter 3 or more characters
    </div>
  )

  return (
    <Combobox
      className={className}
      as="div"
      data-testid="social-search-combobox"
      value={selected}
      onChange={setSelected}
      ref={ref}
    >
      <form className="relative" action="." autoComplete="off">
        <Combobox.Input
          className={clsx(
            'bg-transparent border-none w-full rounded-md py-2 pl-10 hide-cross-icon',
            'text-taupe-100 font-normal text-base leading-5 md:text-sm', // text
            'placeholder:font-medium', // placeholder
            'focus:outline-0 focus:ring-0', // focus
            isFocused ? 'placeholder:text-deep-teal-200' : 'placeholder:text-deep-teal-100', // placeholder & focus
          )}
          type="search"
          placeholder="Search a member"
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          displayValue={() => search}
          {...register('search')}
        />

        <div
          className="absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
          onClick={handleMagnifierClick}
          data-testid="magnifier-button"
        >
          <SearchIcon
            className={clsx('h-5 w-5', isFocused ? 'text-deep-teal-200' : 'text-deep-teal-100')}
            aria-hidden="true"
          />
        </div>

        {!isSearchEmpty && (
          <div
            onClick={handleClearSearch}
            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
            data-testid="clear-button"
          >
            {isFocused && <XCircleIcon className="h-5 w-5 text-orange-500" aria-hidden="true" />}
          </div>
        )}

        {isFocused && (
          <div
            className="fixed z-40 left-0 right-0 top-16 bottom-0 bg-black opacity-30 overflow-hidden md:hidden"
            onClick={handleOverlayClick}
            data-testid="social-search-overlay"
          ></div>
        )}

        <Combobox.Options static={true}>
          {isFocused && (searchHasEnoughLength || showNotEnoughLengthHint) && (
            <div
              className={clsx(
                'fixed z-50 top-[65px] left-0 w-full max-h-[418] ', // mobile position and sizes
                'md:absolute md:top-[50px]', // position and sizes
                'py-6 space-y-2', // box model
                'border-t-1 bg-white rounded-b-md', // styles
                'shadow-lg ring-1 ring-black ring-opacity-5', // shadow
              )}
            >
              {searchHasEnoughLength && thereAreSearchResults && (
                <>
                  {goToAllMembersResultsButton}
                  {members.map((member) => (
                    <MemberSearchOption key={member.userId} member={member} />
                  ))}
                </>
              )}
              {searchHasEnoughLength && thereIsNoResult && noResultsLabel}
              {!searchHasEnoughLength && showNotEnoughLengthHint && notEnoughSearchLength}
            </div>
          )}
        </Combobox.Options>
      </form>
    </Combobox>
  )
}

interface MemberSearchOptionProps extends ComponentProps<'li'> {
  member: Amity.User
}

const MemberSearchOption: FC<MemberSearchOptionProps> = ({ member, ...props }) => {
  return (
    <Combobox.Option
      value={member}
      className={({ active }) =>
        clsx(
          'cursor-pointer select-none px-6 py-2 flex space-x-2 hover:bg-taupe-300',
          active && 'bg-taupe-300',
        )
      }
      {...props}
    >
      <Avatar
        className="w-10 h-10 md:w-6 md:h-6"
        src={member.avatarFileId ? `${AMITY_PATH}/v3/files/${member.avatarFileId}/download` : ''}
      />
      <div className="md:flex md:items-center md:space-x-2.5">
        <div className="flex">
          <div className="text-deep-teal-800 text-sm leading-5 font-normal">
            {member.displayName}
          </div>
          {member.metadata?.memberStatus && (
            <MemberStatus className="ml-2 md:ml-2.5" status={member.metadata?.memberStatus} />
          )}
        </div>
        {member.metadata?.city && (
          <div className="text-sm leading-5 font-normal flex items-center md:space-x-2.5">
            <span className="hidden md:inline text-deep-teal-100">&bull;</span>
            <span className="text-deep-teal-300">{member.metadata?.city}</span>
          </div>
        )}
      </div>
    </Combobox.Option>
  )
}
