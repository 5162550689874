import { createContext } from 'react'
import { SelfAccreditationEnum, RoleEnum } from 'api/dto'

// Auth Context Provider must be implemented on application level.
// It depends on the platform specific SDKs.

export const USER_CUSTOM_CLAIMS = 'https://meetperry.com/user'

export interface UserCustomClaims {
  userId: number
  email: string
  roles: RoleEnum[]
  sa: SelfAccreditationEnum[]
}

export interface IdToken {
  [USER_CUSTOM_CLAIMS]: UserCustomClaims
}

export interface GetAccessTokenOptions {
  ignoreCache?: boolean
}

export interface LoginOptions {
  redirectUri?: string
  appState?: any
}

export interface LogoutOptions {
  returnTo?: string
}

export type AuthContextValue = {
  readonly user?: UserCustomClaims
  readonly role?: RoleEnum
  readonly error?: Error
  readonly isAuthenticated: boolean
  readonly isLoading: boolean
  readonly getAccessToken: (options?: GetAccessTokenOptions) => Promise<string | undefined>
  readonly login: (options?: LoginOptions) => Promise<void>
  readonly logout: (options?: LogoutOptions) => Promise<void>
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  isLoading: false,

  getAccessToken: async () => {
    throw new Error('AuthContext.getAccessToken() not implemented')
  },

  login: async () => {
    throw new Error('AuthContext.login() not implemented')
  },

  logout: async () => {
    throw new Error('AuthContext.logout() not implemented')
  },
})

interface Error {
  name: string
  message: string
  stack?: string
}
