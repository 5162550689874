import { FC } from 'react'
import clsx from 'clsx'

export const ComponentErrorFallback: FC<Readonly<{ className?: string; text?: string }>> = ({
  className,
  text,
}) => (
  <div className={clsx('w-full p-10 h-full', 'flex flex-col items-center', className)}>
    <WarningIcon className="w-8 h-8 mt-auto" />
    <h2 className="text-orange-600 text-xl leading-7 font-medium mt-8 mb-2">
      Something went wrong
    </h2>
    <p className="text-center text-base leading-6 font-normal text-rangitoto-500 mb-auto">
      {text || 'We are working on this issue right now. It will be fixed soon.'}
    </p>
  </div>
)

const WarningIcon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8167 3.43698C16.6518 0.174653 21.3488 0.174652 23.1838 3.43698L36.5765 27.2463C38.3764 30.446 36.0642 34.3995 32.393 34.3995H5.60753C1.93636 34.3995 -0.375869 30.446 1.42396 27.2463L14.8167 3.43698ZM21.4 27.1998C21.4 28.5253 20.3255 29.5998 19 29.5998C17.6746 29.5998 16.6 28.5253 16.6 27.1998C16.6 25.8743 17.6746 24.7998 19 24.7998C20.3255 24.7998 21.4 25.8743 21.4 27.1998ZM19 7.9998C17.6746 7.9998 16.6 9.07432 16.6 10.3998V17.5998C16.6 18.9253 17.6746 19.9998 19 19.9998C20.3255 19.9998 21.4 18.9253 21.4 17.5998V10.3998C21.4 9.07432 20.3255 7.9998 19 7.9998Z"
      fill="#CBCECA"
    />
  </svg>
)
