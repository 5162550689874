import { ComponentType, FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Role, RoleEnum } from 'api/dto'
import { useSession, Session } from 'auth'

/**
 * Wraps a component to protect from accessing by unauthorized users.
 */
export const withAuthRequired = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  requiredRole?: RoleEnum,
): FC<P> => {
  return function WithAuthRequired(props: P): JSX.Element {
    const {
      auth: { isLoading, isAuthenticated, role, error } = {},
      user,
      isInitialized,
      agreements,
      socialProfileNotActivated,
    } = useSession()
    const router = useRouter()
    const [redirected, setRedirected] = useState(false)

    useEffect(() => {
      // If the user is not logged in, redirect to the login page.
      if (!isLoading && !isAuthenticated && !error) {
        const returnTo = router.asPath === RedirectPath.Login ? '' : `?returnTo=${router.asPath}`
        router.push(`${RedirectPath.Login}${returnTo}`)
      }
    }, [isLoading, isAuthenticated, error])

    const hasUnsignedAgreement = agreements?.some((a) => !a.signatureDate)
    const noRequiredRole = role !== requiredRole

    useEffect(() => {
      let unmounted = false

      const redirect = async () => {
        if (noRequiredRole) {
          const redirectTo = DEFAULT_ROLE_ROUTES.get(role) || '/signup/create-account'
          await router.push(redirectTo)
          return
        }

        if (role === Role.INVESTOR && hasUnsignedAgreement) {
          await router.push(RedirectPath.Agreements)
          return
        }

        // There is a splash on main page, which blocks page wether profile not confirmed, except profile page
        const memberProfilePath = `${RedirectPath.MemberProfile}/${user?.id}`
        if (
          role === Role.INVESTOR &&
          socialProfileNotActivated &&
          router.asPath.indexOf(memberProfilePath) < 0
        ) {
          await router.push(memberProfilePath)
          return
        }
      }

      // After session data has been loaded
      if (!isLoading && isAuthenticated && isInitialized) {
        redirect().then(() => {
          // Skip setting the flag if page has been unmounted because of redirect.
          if (!unmounted) {
            setRedirected(true)
          }
        })
      }

      return () => {
        unmounted = true
      }
    }, [isLoading, isAuthenticated, isInitialized, role, noRequiredRole])

    return isInitialized && redirected ? <Component {...props} /> : <></>
  }
}

/**
 * Denies access for authenticated users.
 */
export const withDenyingAccessForAuthenticated = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): FC<P> => {
  return function WithDenyingAccessForAuthenticated(props: P): JSX.Element {
    const router = useRouter()
    const { auth: { isLoading, isAuthenticated } = {} } = useSession()

    useEffect(() => {
      if (!isLoading && isAuthenticated) {
        router.push('/')
      }
    }, [isLoading, isAuthenticated, router])

    return isLoading || isAuthenticated ? <></> : <Component {...props} />
  }
}

export const withSignupRedirect = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): FC<P> => {
  return function WithSignupRedirect(props: P): JSX.Element {
    const [attemptedToRedirect, setAttemptedToRedirect] = useState(false)
    const session = useSession()
    const router = useRouter()

    useEffect(() => {
      let unmounted = false

      const redirect = async () => {
        if (shouldRedirectToCreateAccount(session)) {
          await router.replace('/signup/create-account')
        } else if (shouldRedirectToAccountDetails(session)) {
          await router.replace('/signup/account-details')
        } else if (shouldRedirectToPayment(session)) {
          await router.replace('/signup/payment')
        } else {
          await router.replace('/signup/complete')
        }
      }

      // After session data has been loaded
      if (session.isInitialized) {
        redirect().then(() => {
          // Skip setting the flag if page has been unmounted because of redirect.
          if (!unmounted) {
            setAttemptedToRedirect(true)
          }
        })
      }

      return () => {
        unmounted = true
      }
    }, [
      session.isInitialized,
      session?.user?.id,
      JSON.stringify(session.sa),
      session.subscription?.id,
    ])

    return !session.isInitialized || !attemptedToRedirect ? <></> : <Component {...props} />
  }
}

const shouldRedirectToCreateAccount = (session: Session): boolean => {
  return session.auth?.isAuthenticated && !session.user
}

const shouldRedirectToAccountDetails = (session: Session): boolean => {
  return session.auth?.isAuthenticated && session.user && !session.sa
}

const shouldRedirectToPayment = (session: Session): boolean => {
  return (
    session.auth.isAuthenticated &&
    session.sa &&
    (!session.subscription || session.subscription.status !== 'SUCCESS')
  )
}

const DEFAULT_ROLE_ROUTES = new Map([
  [Role.ADMIN, '/admin'],
  [Role.SIGNUP, '/signup/create-account'],
  [Role.INVESTOR, '/'],
])

enum RedirectPath {
  Main = '/',
  Admin = '/admin',
  Login = '/login',
  Agreements = '/agreements',
  MemberProfile = '/members',
}
