import { FC } from 'react'
import { Tag } from '@perry/app/components/content/Tag'
import { AttributesSection } from './AttributesSection'
import { Clamp } from './Clamp'
import { Insight } from './Insight'
import type { InvestmentOpportunityProps } from './InvestmentOpportunity'

export const FrontSide: FC<InvestmentOpportunityProps> = ({
  state,
  insight,
  logoUrl,
  investmentOpportunityCard,
}) => {
  return (
    <>
      <div>{logoUrl && <img className="h-9" src={logoUrl} />}</div>

      <div className="mt-2 font-semibold text-base text-deep-teal-900">
        {investmentOpportunityCard.title}
      </div>

      <div className="flex gap-1 mt-2">
        <Tag content={investmentOpportunityCard.fundType} />
        <Tag
          className="capitalize"
          content={state}
          state={state == 'open' ? 'active' : 'default'}
        />
      </div>

      <AttributesSection className="mt-2" fields={investmentOpportunityCard.additionalAttributes} />

      <Clamp
        content={investmentOpportunityCard.about}
        className="relative mt-1 text-sm text-deep-teal-300 flex-1"
      />
      <Insight className="mt-auto mb-0" insight={insight} />
    </>
  )
}
