import { forwardRef, InputHTMLAttributes } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import clsx from 'clsx'

const parseToNumber = (str?: string): number | undefined => {
  if (!str) return
  const stringNumber = str.replace(/[^0-9.]/g, '')
  return Number.parseFloat(stringNumber)
}

export interface CurrencyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly control?: Control<any>
}

// eslint-disable-next-line react/display-name
export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ name, className, control, ...props }, ref) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <IMaskInput
                {...props}
                ref={ref}
                type="text"
                lazy={true}
                mask={Number}
                signed={false}
                thousandsSeparator={','}
                padFractionalZeros={true}
                radix={'.'}
                name={name}
                onBlur={field.onBlur}
                onAccept={(value?: string) => field.onChange(parseToNumber(value))}
                className={clsx('pl-6', className)}
              />
            </div>
          )}
        />
      </>
    )
  },
)

export default CurrencyInput
