import { DependencyList, useEffect } from 'react'

const useDelayEffect = (callback: () => void, delay: number, deps: DependencyList = []): void => {
  useEffect(() => {
    const timer = setTimeout(() => callback(), delay)
    return () => clearTimeout(timer)
  }, deps)
}

export default useDelayEffect
