import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Role, User } from 'api/dto'
import { useSession } from 'auth'
import { LDFlag, useFlag } from '@perry/app/lib/launchdarkly'

export interface NavigationItem {
  readonly name: string
  readonly href?: string
  readonly target?: string
  readonly current?: boolean
  readonly className?: string
  readonly onClick?: () => void
}

const commonMenu: NavigationItem[] = [
  {
    name: 'Sign out',
    href: '/logout',
  },
]

const adminMenu: NavigationItem[] = [
  {
    name: 'Admin',
    href: '/admin',
  },
  {
    name: 'CMS content',
    href: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}/admin`,
    target: '_blank',
  },
]

const investorMenu: NavigationItem[] = [
  {
    name: 'Profile',
    href: '/profile',
  },
  {
    name: 'Nominations',
    href: '/nominations',
    className: 'md:hidden',
  },
]

const connectionsItem: NavigationItem = {
  name: 'Connections',
  href: '/connections',
}

export const useUserNavigation = (): NavigationItem[] => {
  const router = useRouter()
  const { user } = useSession()
  const isAdmin = user?.roles?.includes(Role.ADMIN)
  const isInvestor = user?.roles?.includes(Role.INVESTOR)

  const socialConnectionsFlag = useFlag(LDFlag.SocialConnections)

  const [navigation, setNavigation] = useState<NavigationItem[]>([
    ...(isAdmin ? adminMenu : []),
    ...(isInvestor ? investorMenu : []),
    ...commonMenu,
  ])

  useEffect(() => {
    setNavigation(navigation.map((item) => checkIsNavItemCurrent(item, router.pathname)))
  }, [router.pathname, JSON.stringify(navigation)])

  useEffect(() => {
    if (socialConnectionsFlag) {
      setNavigation([
        ...(isAdmin ? adminMenu : []),
        ...(isInvestor ? investorMenu : []),
        connectionsItem,
        ...commonMenu,
      ])
    }
  }, [socialConnectionsFlag])
  return navigation
}

const checkIsNavItemCurrent = (item: NavigationItem, pathname: string) => {
  return {
    ...item,
    current: pathname.startsWith(item.href),
  }
}

export const getInitials = (user: User): string => {
  return [user?.firstName, user?.lastName]
    .filter(Boolean)
    .map((name) => name.substring(0, 1)?.toUpperCase())
    .join('')
}
