import { ComponentProps, FC, useEffect } from 'react'
import Link from 'next/link'
import { useAmity } from 'amity/lib'
import clsx from 'clsx'
import { useWidgetStore } from './GroupsWidget.store'
import { GroupsWidgetItem } from './GroupsWidgetItem'

type GroupsWidgetProps = ComponentProps<'div'>

const getViewMoreText = (groups: Amity.Community[]): string =>
  groups?.length > 2 ? 'View All' : 'View More'

export const GroupsWidget: FC<GroupsWidgetProps> = ({ className }) => {
  const { loaded, load, groups } = useWidgetStore()
  const { isConnected } = useAmity()
  useEffect(() => {
    isConnected && load()
  }, [isConnected])

  return (
    <div
      className={clsx(
        'bg-white rounded-lg shadow px-4 py-3 flex sm:items-center items-start gap-4',
        'sm:flex-row flex-col',
        className,
      )}
    >
      <div className="leading-4 flex w-full sm:w-auto text-deep-teal-900 text-base whitespace-nowrap font-medium">
        My groups:
        {groups?.length > 0 && (
          <Link
            href="/groups"
            className="mr-0 ml-auto sm:hidden pl-2 inline-block whitespace-nowrap text-sm font-semibold text-orange-600 hover:text-orange-800"
          >
            {getViewMoreText(groups)}
          </Link>
        )}
      </div>
      <div className="leading-4 text-deep-teal-800">
        {!loaded && <div className="py-1 w-[400px]">Loading groups...</div>}

        {loaded && <GroupsList groups={groups} />}
      </div>
    </div>
  )
}

const GroupsList: FC<{ groups: Amity.Community[] }> = ({ groups }) =>
  groups?.length > 0 ? (
    <div className="flex sm:flex-row flex-col gap-2 sm:items-center">
      {groups.map((g) => (
        <GroupsWidgetItem group={g} key={g.communityId} />
      ))}
      <Link
        href="/groups"
        className="hidden sm:block pl-2 self-center whitespace-nowrap text-sm font-semibold text-orange-600 hover:text-orange-800"
      >
        {getViewMoreText(groups)}
      </Link>
    </div>
  ) : (
    <>
      <span>You&apos;re not part of any group yet. </span>
      <Link
        href="/groups"
        className="pr-8 inline-block text-sm font-semibold text-orange-600 hover:text-orange-800"
      >
        See the full list to join one now.
      </Link>
    </>
  )
