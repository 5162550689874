import { FC, useEffect, useState } from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import clsx from 'clsx'

export interface SwitchProps {
  readonly value?: boolean
  readonly onChange?: (value: boolean) => void
}

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false)
  useEffect(() => setDidMount(true))

  return didMount
}

export const Switch: FC<SwitchProps> = ({ value, onChange }) => {
  const [enabled, setEnabled] = useState(!!value)
  const didMount = useDidMount()

  useEffect(() => {
    if (onChange && didMount) {
      onChange(enabled)
    }
  }, [enabled])

  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={setEnabled}
      className={clsx(
        enabled ? 'bg-orange-500 hover:bg-orange-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
      )}
    >
      <span
        aria-hidden="true"
        className={clsx(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </HeadlessSwitch>
  )
}
