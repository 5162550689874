import { FC, HTMLAttributes } from 'react'
import Head from 'next/head'
import clsx from 'clsx'
import { UserMenu } from '@perry/app/components/layouts/Toolbar/UserMenu'

const SignupLayout: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  title,
  ...props
}) => {
  return (
    <>
      <Head>{title && <title>{title}</title>}</Head>

      <style jsx global>
        {`
          body {
            background-color: #0a373c !important;
          }
        `}
      </style>

      <div id="signupContent" className={clsx('bg-deep-teal-500', className)} {...props}>
        <div className="fixed p-12 inset-0 bg-[url(/images/bg-texture-swirls.svg)] bg-fixed" />
        <div className="relative p-4 md:p-24">
          <UserMenu
            className="md:fixed -top-2 sm:-right-2 md:top-16 md:right-16 flex justify-end"
            standalone
          />
          {children}
        </div>
      </div>
    </>
  )
}

export default SignupLayout
