import { ComponentProps, FC } from 'react'
import { InvestmentOpportunity } from 'api/cms'
import clsx from 'clsx'
import { InvestmentOpportunity as InvestmentOpportunityComponent } from './InvestmentOpportunity'

export interface InvestmentOpportunitiesProps extends ComponentProps<'div'> {
  readonly investmentOpportunities: InvestmentOpportunity[]
}

export const InvestmentOpportunities: FC<InvestmentOpportunitiesProps> = ({
  investmentOpportunities,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-4',
        className,
      )}
      {...props}
    >
      {investmentOpportunities
        .filter((io) => !!io.investmentOpportunityCard)
        .map((io, index) => (
          <InvestmentOpportunityComponent
            key={index}
            investmentOpportunityCard={io.investmentOpportunityCard}
            insight={io.insight}
            state={io.state}
            investmentId={io.id}
            logoUrl={io.sponsor?.logo?.url}
            className="h-96"
          />
        ))}
    </div>
  )
}
