import { getMyGroups } from 'amity/lib'
import create from 'zustand'

interface GroupsWidgetStates {
  loaded: boolean
  load(): Promise<void>
  groups: Amity.Community[]
}

export const useWidgetStore = create<GroupsWidgetStates>((set) => ({
  loaded: false,
  groups: [],
  load: async () => {
    const { groups } = await getMyGroups({ page: { limit: 3 } })
    set({
      loaded: true,
      groups: groups,
    })
  },
}))
