import { FC, ReactNode, useEffect, useState } from 'react'
import { useSession } from 'auth'
import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk'

export const LaunchDarklyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, sa } = useSession()
  const [ldUser, setLdUser] = useState<ProviderConfig['user']>()

  useEffect(() => {
    if (user) {
      setLdUser({
        key: user.id.toString(),
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        custom: {
          roles: user.roles || [],
          sa: sa || [],
        },
      })
    }
  }, [user])

  return (
    <LDProvider
      clientSideID={process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID}
      deferInitialization={true}
      user={ldUser}
    >
      {children}
    </LDProvider>
  )
}
