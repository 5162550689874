import { Children, cloneElement, FC, HTMLAttributes, ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'
import Error from './Error'
import Label from './Label'

export interface FieldProps extends HTMLAttributes<HTMLElement> {
  readonly name: string
  readonly label?: string
  readonly error?: Pick<FieldError, 'message'>
  readonly disabled?: boolean
  readonly hideError?: boolean
}

const Field: FC<FieldProps> = ({
  name,
  label,
  children,
  error,
  disabled,
  hideError,
  className,
  ...props
}) => {
  const child = Children.only(children) as ReactElement
  const input = cloneElement(child, {
    name,
    id: name,
    disabled,
    className: clsx(
      child.props?.className,
      'block w-full py-2 px-3', // Alignment
      'rounded-md shadow-sm border-taupe-600 hover:border-taupe-700 focus:ring-0 ', // Border
      'text-sm leading-5 text-deep-teal-900 ', // Text
      error
        ? [
            'appearance-none bg-none', // suppressing native element styling (select, number, etc.)
            'border-pure-red hover:border-pure-red focus:border-pure-red',
          ]
        : 'focus:border-navy-500',
      { 'cursor-not-allowed': disabled },
    ),
    'aria-invalid': error && !!error,
    'aria-describedby': error && `${name}-error`,
  })

  return (
    <div className={clsx({ 'opacity-25': disabled }, className)} {...props}>
      {label && <Label name={name}>{label}</Label>}

      <div className={clsx({ 'mt-1': label }, error && 'rounded-md shadow-sm')}>{input}</div>

      {!hideError && (
        <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
          {error?.message}
        </Error>
      )}
    </div>
  )
}

export default Field
