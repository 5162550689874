import { FC, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { InvestmentOpportunityCard } from 'api/cms'
import clsx from 'clsx'
import { ComponentErrorFallback } from '@perry/app/components/content'
import { useErrorCallback } from '@perry/app/lib'
import { Actions } from './Actions'
import { BackSide } from './BackSide'
import { FrontSide } from './FrontSide'

export interface InvestmentOpportunityProps {
  readonly className?: string
  readonly investmentId: number
  readonly state: 'open' | 'closed'
  readonly insight?: string
  readonly logoUrl?: string
  readonly investmentOpportunityCard: Partial<InvestmentOpportunityCard>
}

const getContainerClasses = (className?: string) =>
  clsx(
    'px-4 py-5 lg:px-5 lg:py-6',
    'backface-visibility-hidden absolute w-full h-full',
    'flex flex-col',
    'bg-white rounded-lg shadow',
    className,
  )

export const InvestmentOpportunity: FC<InvestmentOpportunityProps> = ({
  investmentId,
  state,
  insight,
  logoUrl,
  investmentOpportunityCard,
  className,
}) => {
  const [flipped, setFlipped] = useState(false)
  const onErrorCallback = useErrorCallback(InvestmentOpportunity)

  return (
    <ErrorBoundary onError={onErrorCallback} FallbackComponent={ErrorFallback}>
      <div className={clsx('relative', className)}>
        <div
          className={clsx(
            'w-full h-full absolute',
            'transform-preserve-3d duration-500',
            flipped && 'rotate-y-180',
          )}
        >
          <div className={getContainerClasses()}>
            <FrontSide
              investmentId={investmentId}
              state={state}
              insight={insight}
              logoUrl={logoUrl}
              investmentOpportunityCard={investmentOpportunityCard}
            />
            <Actions
              className="mt-4 mb-0"
              isFlipable={investmentOpportunityCard.backPanel?.length > 0}
              investmentId={investmentId}
              flipped={flipped}
              onFlip={(val) => setFlipped(val)}
            />
          </div>
          {investmentOpportunityCard.backPanel?.length > 0 && (
            <div className={getContainerClasses('rotate-y-180')}>
              <BackSide investmentOpportunityCard={investmentOpportunityCard} />
              <Actions
                className="mt-auto mb-0"
                isFlipable={investmentOpportunityCard.backPanel?.length > 0}
                investmentId={investmentId}
                flipped={flipped}
                onFlip={(val) => setFlipped(val)}
              />
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

const ErrorFallback: FC = () => (
  <ComponentErrorFallback
    className="bg-white rounded-lg shadow"
    text="We are working on updating the opportunity right now. This will be fixed soon."
  />
)
