import { FC } from 'react'
import { RefreshIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Button } from '@perry/app/components/forms'

interface ActionsProps {
  readonly className?: string
  readonly investmentId: number
  readonly isFlipable: boolean
  readonly flipped: boolean
  readonly onFlip: (flipped: boolean) => void
}

export const Actions: FC<ActionsProps> = ({
  className,
  investmentId,
  isFlipable,
  flipped,
  onFlip,
}) => {
  return (
    <div className={clsx('flex justify-between', className)}>
      {isFlipable && (
        <Button size="sm" uiType="secondary" className="flex" onClick={() => onFlip(!flipped)}>
          Details
          <RefreshIcon className="h-3 w-3 ml-2 text-orange-600" />
        </Button>
      )}
      <Button
        href={`/investments/${investmentId}`}
        size="sm"
        className="ml-auto"
        data-trackclick={JSON.stringify({
          action: `Investment Opportunity Details Opened`,
          investmentOpportunityId: investmentId,
        })}
      >
        Full information
      </Button>
    </div>
  )
}
