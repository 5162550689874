import { ComponentProps, FC } from 'react'
import { UserIcon } from '@heroicons/react/solid'
import clsx from 'clsx'

export interface AvatarProps extends ComponentProps<'div'> {
  src?: string
}

const Avatar: FC<AvatarProps> = ({ className, src }) => {
  return (
    <div className={clsx('bg-taupe-500 overflow-hidden rounded-full', className)}>
      {src ? (
        <img className="h-full w-full" src={src} data-testid="avatar" />
      ) : (
        <UserIcon
          className="h-[110%] w-[110%] mb-[-10%] ml-[-5%] text-deep-teal-500"
          data-testid="default-avatar"
        />
      )}
    </div>
  )
}

export default Avatar
