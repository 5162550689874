import { FC, ReactNode, useCallback, useContext, useRef } from 'react'
import { axios } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import { AuthContext } from './AuthContext'

export const AxiosAuthInterceptorInitializer: FC<{ children: ReactNode }> = ({ children }) => {
  const { getAccessToken } = useContext(AuthContext)

  const instance = useRef(axios)
  const api = instance.current

  const appendAuthHeader = useCallback(
    async (config: AxiosRequestConfig) => {
      if (
        config.url?.startsWith('/api') &&
        !config.url?.startsWith('/api/public') &&
        !config.url?.startsWith('/api/auth0/public') &&
        !config.headers?.Authorization // Do NOT override header if it's explicitly provided.
      ) {
        const accessToken = await getAccessToken()
        config!.headers!.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    [getAccessToken],
  )

  api.interceptors.request.use(appendAuthHeader)

  // Make sure that that interceptor used in both projects
  const handleApiError = useCallback((error: any) => {
    // Override the default Axios HTTP error message
    // with the message received from the server.
    if (error.response?.data?.message) {
      throw {
        ...error,
        code: error.response.data.code,
        message: error.response.data.message,
      }
    }
    throw error
  }, [])

  axios.interceptors.response.use((response) => response, handleApiError)

  return <>{children}</>
}
