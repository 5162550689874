import { FC } from 'react'
import { FireIcon, StarIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import MarkdownProse from '../../MarkdownProse/MarkdownProse'

const iconClasses = 'h-5 w-5 text-orange-500'

export const Insight: FC<{ insight: string; className?: string }> = ({ insight, className }) => {
  return (
    <div className={clsx('flex text-deep-teal-900', className)}>
      {insight ? <StarIcon className={iconClasses} /> : <FireIcon className={iconClasses} />}
      {insight ? (
        <MarkdownProse
          className="ml-2 text-sm prose-strong:text-deep-teal-900"
          markdown={insight}
        />
      ) : (
        <span className="ml-2 font-bold">New opportunity</span>
      )}
    </div>
  )
}
