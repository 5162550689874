import { FC, Fragment, HTMLAttributes, ReactNode } from 'react'
import { Menu as HeadlessMenu, Transition } from '@headlessui/react'
import { Options as PopperOptions } from '@popperjs/core'
import clsx from 'clsx'
import { usePopper } from '@perry/app/lib'

export interface MenuProps extends HTMLAttributes<HTMLButtonElement> {
  readonly button: ReactNode
  readonly items: MenuItem[]
  readonly popperOptions?: Partial<PopperOptions>
}

export interface MenuItem {
  readonly action: () => void
  readonly label: string
  readonly disabled?: boolean
}

const DEFAULT_POPPER_OPTIONS: Partial<PopperOptions> = {
  strategy: 'fixed',
  placement: 'auto',
  modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
}

const Menu: FC<MenuProps> = ({ button, items, popperOptions, ...props }) => {
  const [trigger, container] = usePopper({ ...DEFAULT_POPPER_OPTIONS, ...popperOptions })

  return (
    <HeadlessMenu as="div">
      {({ open }) => (
        <>
          <div>
            <HeadlessMenu.Button ref={trigger} {...props}>
              {button}
            </HeadlessMenu.Button>
          </div>

          <div ref={container} className="w-56">
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <HeadlessMenu.Items
                className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                static
              >
                <div className="py-1 flex flex-col">
                  {items.map((item) => (
                    <HeadlessMenu.Item key={item.label}>
                      {({ active }) => (
                        <button
                          onClick={item.action}
                          disabled={item.disabled}
                          className={clsx(
                            { 'bg-gray-100': active && !item.disabled },
                            { 'opacity-50 cursor-not-allowed': item.disabled },
                            'block px-4 py-2 text-sm text-gray-700 text-left',
                          )}
                        >
                          {item.label}
                        </button>
                      )}
                    </HeadlessMenu.Item>
                  ))}
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </div>
        </>
      )}
    </HeadlessMenu>
  )
}

export default Menu
