import { FC, HTMLAttributes, SVGAttributes } from 'react'
import Link from 'next/link'
import { HomeIcon } from '@heroicons/react/solid'
import clsx from 'clsx'

export interface BreadcrumbsItem {
  readonly name: string
  readonly href: string
  readonly current: boolean
}

export interface BreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
  readonly items: BreadcrumbsItem[]
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ className, items, ...props }) => {
  return (
    <nav className={clsx('flex', className)} aria-label="Breadcrumb" {...props}>
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {items.map((item) => (
          <li key={item.name}>
            <div className="flex items-center">
              <SlashIcon className="text-gray-300" />
              <Link
                href={item.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs

const SlashIcon: FC<SVGAttributes<SVGAElement>> = ({ className }) => (
  <svg
    className={clsx('shrink-0 h-5 w-5', className)}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
)
