import { FC } from 'react'
import { Button } from '@perry/app/components/forms'
import { Footer } from '@perry/app/components/layouts'
import { BaseToolbar, Logo } from '../../layouts/Toolbar/BaseToolbar'
import { Metadata } from '../../metadata'

export const GlobalErrorFallback: FC = () => {
  return (
    <>
      <Metadata title="meetperry | Exception" />
      <div className="min-h-full flex flex-col bg-white">
        <BaseToolbar
          className="z-10"
          content={() => (
            <>
              <Logo link="/" />
              <div className="flex-1" />
            </>
          )}
        />
        <div className="flex-1 px-2 py-8 sm:py-8 sm:px-4 flex flex-col items-center bg-rangitoto-50">
          <div
            role="alert"
            className="flex-1 flex sm:p-20 bg-white rounded-lg w-full max-w-6xl shadow px-6 py-20"
          >
            <div className="flex items-center flex-col min-w-0 gap-x-24 md:flex-row-reverse ">
              <Icon className="h-24 md:h-44 mb-20" />
              <div className="flex items-center flex-col md:items-start">
                <h1 className="text-3xl text-center md:text-left md:text-5xl text-rangitoto-500 leading-none font-extrabold">
                  Looks like something went wrong
                </h1>
                <p className="mt-1 text-xl leading-7 font-normal">
                  We are working to fix the issue
                </p>

                <Button
                  uiType="primary"
                  className="mt-10"
                  onClick={() => location.replace('/')}
                  size="md"
                >
                  Back to homepage
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

const Icon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 460 166" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M452.462 90.444H391.759C391.96 87.9819 392.161 85.4696 392.161 82.907C392.161 77.7316 391.608 72.7069 390.703 67.833H452.462C456.633 67.833 460 64.4665 460 60.296C460 56.1255 456.633 52.759 452.462 52.759H386.382C374.271 21.9578 344.322 0 309.246 0H279.095C274.925 0 271.558 3.36653 271.558 7.537V158.277C271.558 162.447 274.925 165.814 279.095 165.814H309.246C347.085 165.814 379.045 140.238 388.894 105.518H452.412C456.583 105.518 459.95 102.151 459.95 97.981C459.95 93.8105 456.583 90.444 452.462 90.444ZM286.583 15.074H301.658V150.74H286.583V15.074ZM316.784 150.288V15.5262C350.653 19.2947 377.085 48.086 377.085 82.907C377.035 117.778 350.603 146.519 316.784 150.288Z"
      fill="#CBCECA"
    />
    <path
      d="M226.131 75.37C235.226 75.37 248.744 69.3404 248.744 52.759C248.744 36.1776 235.226 30.148 226.131 30.148H188.442V7.537C188.442 3.36653 185.075 0 180.905 0H150.754C112.915 0 80.9548 25.5755 71.1055 60.296H7.53769C3.36683 60.296 0 63.6625 0 67.833C0 72.0035 3.36683 75.37 7.53769 75.37H68.2412C68.0402 77.8321 67.8392 80.3444 67.8392 82.907C67.8392 88.0824 68.392 93.107 69.2965 97.981H7.53769C3.36683 97.981 0 101.348 0 105.518C0 109.688 3.36683 113.055 7.53769 113.055H73.6181C85.7286 143.856 115.678 165.814 150.754 165.814H180.905C185.075 165.814 188.442 162.447 188.442 158.277V135.716H226.131C235.226 135.716 248.744 129.687 248.744 113.105C248.744 96.5238 235.226 90.4942 226.131 90.4942H188.442V75.37H226.131ZM226.131 45.222C228.392 45.222 233.668 45.9757 233.668 52.759C233.668 58.8388 229.497 60.1955 226.131 60.296H188.442V45.222H226.131ZM82.9146 82.907C82.9146 48.0358 109.347 19.2947 143.216 15.5262V150.288C109.347 146.519 82.9146 117.778 82.9146 82.907ZM173.367 150.74H158.291V15.074H173.367V150.74ZM226.131 105.568C228.392 105.568 233.668 106.322 233.668 113.105C233.668 119.185 229.497 120.542 226.131 120.642H188.442V105.568H226.131Z"
      fill="#CBCECA"
    />
  </svg>
)
