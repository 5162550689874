import { FC } from 'react'
import clsx from 'clsx'

export const Tag: FC<{ content: string; className?: string; state?: 'default' | 'active' }> = ({
  content,
  className,
  state = 'default',
}) => {
  return (
    <div
      className={clsx(
        'h-5 leading-5 inline-block px-2.5 rounded-[10px] self-start text-xs whitespace-nowrap font-semibold',
        state == 'active'
          ? 'text-success-green-800 bg-success-green-300'
          : 'text-deep-gray-600 bg-deep-gray-100',
        className,
      )}
    >
      {content}
    </div>
  )
}
