import { ComponentProps, FC } from 'react'
import { XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'

interface TagProps extends ComponentProps<'span'> {
  tag: string
  onRemove: (tag: string) => void
}

export const Tag: FC<TagProps> = ({ tag, onRemove }) => (
  <span
    className={clsx(
      'flex items-center w-fit shrink-0 grow-0 my-1 mr-1', // box model
      'bg-deep-teal-50 rounded-full', // background
    )}
  >
    <span className="text-deep-teal-500 text-xs leading-5 font-semibold pl-2">{tag}</span>
    <XIcon
      data-testid="tag-remove-button"
      className="text-deep-teal-500 w-3 h-3 ml-0.5 mr-1 cursor-pointer"
      onClick={() => onRemove(tag)}
    />
  </span>
)
