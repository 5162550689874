import { FC, useEffect, useRef } from 'react'
import clsx from 'clsx'

const getLineHeight = (htmlElement: HTMLElement): number => {
  const lineHeight = getComputedStyle(htmlElement, 'line-height')
  if (lineHeight === 'normal' || !lineHeight) {
    return parseInt(getComputedStyle(htmlElement, 'font-size')) * 1.2
  }
  if (!lineHeight?.indexOf('px')) {
    // if the line-height value not set in pixels nor default we can not guaranty the correct behavior
    console.warn(
      'Clamp component correctly works only with "line-height" value equal to "normal" or some pixel value.',
    )
  }
  return parseInt(lineHeight)
}

const getComputedStyle = (htmlElement: HTMLElement, styleProp): string => {
  if (window.getComputedStyle)
    return document.defaultView.getComputedStyle(htmlElement, null)?.getPropertyValue(styleProp)
  return ''
}

const appendMultilineEllipsisStyles = (
  htmlElement: HTMLElement,
  numberOfLines: number,
  lineHeight: number,
) => {
  htmlElement.style.display = '-webkit-box'
  htmlElement.style.webkitLineClamp = numberOfLines.toString()
  htmlElement.style['-webkit-box-orient'] = 'vertical'
  htmlElement.style.maxHeight = `${numberOfLines * lineHeight}px`
}

const onResize = (containerDivHeight: number, div: HTMLDivElement) => {
  const lineHeight = getLineHeight(div)
  const numberOfLines = Math.floor(containerDivHeight / lineHeight)
  appendMultilineEllipsisStyles(div, numberOfLines, lineHeight)
}

export const Clamp: FC<{ className?: string; content: string }> = ({ className, content }) => {
  const containerDivRef = useRef<HTMLDivElement>(null)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!divRef.current || !containerDivRef.current) return

    const resizeHandler = () => onResize(containerDivRef.current.offsetHeight, divRef.current)

    // if there is resize observer will use it to track resize of div
    if (window.ResizeObserver) {
      const ro = new ResizeObserver(resizeHandler)
      ro.observe(divRef.current)
      return () => ro.disconnect()
    } else {
      window.addEventListener('resize', resizeHandler)
      return () => window.removeEventListener('resize', resizeHandler)
    }
  }, [divRef.current])

  return (
    <div className={clsx('overflow-hidden', className)} ref={containerDivRef} data-testid="clamp">
      <div className="overflow-hidden" ref={divRef}>
        {content}
      </div>
    </div>
  )
}
