import mixpanelPlugin from '@analytics/mixpanel'
import Analytics, { AnalyticsInstance } from 'analytics'
import jsEventsTrack from './plugins/js-events-track'

const isBrowser = typeof window !== 'undefined'

const analytics: AnalyticsInstance | null = isBrowser
  ? Analytics({
      app: 'meetperry',
      plugins: [
        mixpanelPlugin({
          token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
          options: { api_host: '/api/analytics' },
        }),
        jsEventsTrack(),
      ],
    })
  : null

export default analytics
