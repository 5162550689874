import { FC, HTMLAttributes } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'

export const NavTabs: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children }) => {
  return (
    <div className={clsx(className, 'border-b border-deep-teal-100')}>
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {children}
      </nav>
    </div>
  )
}
/**
 * Navigation Tab function component
 * @param title - title of the tab
 * @param path - full relative path to the tab content page (from root)
 * @constructor
 */
export const NavTab: FC<{
  className?: string
  title: string
  path: string
}> = ({ className, title, path }) => {
  const router = useRouter()
  const selected = router.pathname === path
  return (
    <Link
      href={path}
      className={clsx(
        className,
        selected
          ? 'border-orange-500 text-orange-600 font-bold'
          : 'border-transparent text-deep-teal-500 hover:text-deep-teal-500 hover:border-deep-teal-200 font-medium',
        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
      )}
      role="link"
      aria-current={selected ? 'page' : undefined}
    >
      {title}
    </Link>
  )
}
