import { ComponentType, useCallback } from 'react'
import { LogEntry } from 'api/dto'
import { axios } from 'api/lib'

export const sendError = async (logEntry: LogEntry): Promise<void> => {
  return axios.post('/api/log', logEntry)
}

export type ErrorCallback = (error: Error, info: { componentStack: string }) => void

export const useErrorCallback = (component: ComponentType | string): ErrorCallback => {
  const componentName = getDisplayName(component)
  const callback = useCallback<ErrorCallback>(
    (error, info) => {
      sendError({
        error,
        message: error?.message || 'Something went wrong',
        context: { component: componentName, ...info },
      })
    },
    [componentName],
  )
  return callback
}

function getDisplayName(component: ComponentType | string): string {
  return typeof component === 'string'
    ? component
    : component.displayName || component.name || 'Component'
}
