import { ComponentType, FC, useEffect, useState } from 'react'
import Error from 'next/error'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { LDFlag, useFlag } from './useFlag'

/**
 * Wraps a component to protect from accessing by users who
 * do not have specific LaunchDarkly flag enabled
 */
export const withLDFlagRequired = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  flag: LDFlag,
): FC<P> => {
  return function WithLDFlagRequired(props: P): JSX.Element {
    const ldFlag = useFlag<boolean>(flag)
    const ldInitialized = useLDInitialized()

    if (!ldInitialized) {
      return <></>
    }

    return ldFlag ? (
      <Component {...props} />
    ) : (
      // If the flag is false - show 404 error page
      <Error withDarkMode={false} className="text-rangitoto-700" statusCode={404} />
    )
  }
}

const useLDInitialized = () => {
  const [initialized, setInitialized] = useState(false)
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient) {
      ldClient.waitForInitialization().then(() => setInitialized(true))
    }
  }, [ldClient])

  return initialized
}
